export enum GameStatus {
  NONE = "NONE",
  LOBBY = "LOBBY",
  RUNNING = "RUNNING",
  FINISHED = "FINISHED",
  COUNTDOWN = "COUNTDOWN",
}

export const GAME_ID_CHARACTERS = "ABCDEFGHIJKLMNPQRSTUVWXYZ123456789";
export const GAME_ID_LENGTH = 4;
